import React from "react";

import { StyleSheet, css } from "aphrodite";

import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const styles = StyleSheet.create({});

export default function Project(props) {
  return (
    <div
      style={{ backgroundColor: "#F4F4F4", margin: "2em", height: 490 }}
      onClick={props.onClick}
    >
      <Image src={"images/" + props.image} style={{ width: "100%" }} />
      <div style={{ padding: "1em" }}>
        <h3 style={{ fontSize: 18, padding: "0.2em 0 0.2em 0" }}>
          {props.title}
        </h3>
        <div style={{ borderBottom: "1px solid #000" }}></div>
        <p style={{ marginTop: "0.6em" }}>{props.description}</p>
      </div>
    </div>
  );
}
