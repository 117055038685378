import React, { useState } from "react";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import Portfolio from "./components/portfolio";
// import Map1 from "./components/map1";
// import Map2 from "./components/map2";
// import Map3 from "./components/map3";
// import MESenate from "./components/me-senate";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Portfolio />}>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
