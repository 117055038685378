import React, { useState } from "react";

import { StyleSheet, css } from "aphrodite";

import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Project from "../components/project";
import Slideshow from "../components/slideshow";

import projects from "../assets/projects";

const styles = StyleSheet.create({});

export default function Design(props) {
  const [show, setShow] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = p => {
    setShow(true);
    setSelectedProject(p);
  };

  return (
    <Container className="pageContainer" id="design">
      <Slideshow
        show={show}
        project={selectedProject}
        handleClose={handleClose}
      />
      <Row>
        <Col md={{ span: 4 }}>
          <h2 style={{ fontSize: 36 }}>Design</h2>
        </Col>
      </Row>
      <Row style={{ marginTop: "1em" }}>
        {projects.map(p => (
          <Col md={{ span: 6 }} key={p.name}>
            <Project
              onClick={e => handleShow(p)}
              title={p.name}
              image={p.image}
              description={p.description}
            />
          </Col>
        ))}
      </Row>
      <Row>
        <div>
          (<a href="#top">Back</a>)
        </div>
      </Row>
    </Container>
  );
}
