import React from "react";

import { StyleSheet, css } from "aphrodite";

import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const styles = StyleSheet.create({});

export default function About(props) {
  return (
    <div style={{ background: "url(bg2.png)", backgroundSize: "100%" }}>
      <Container className="pageContainer" id="about">
        <Row>
          <Col md={{ span: 4 }}>
            <h2 style={{ fontSize: 36 }}>About</h2>
          </Col>
        </Row>
        <Row style={{ marginTop: "2em" }}>
          <Col md={{ span: 4 }}>
            <Image
              src="images/Saenger_Headshot_Square.jpeg"
              roundedCircle
              style={{ width: "100%", margin: "auto" }}
            />
            <div
              style={{
                textAlign: "center",
                marginTop: "2em",
                marginBottom: "1em"
              }}
            >
              <span class="socialLink">
                <a
                  href="https://twitter.com/saengerleo"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  twitter
                </a>
              </span>{" "}
              •{" "}
              <span class="socialLink">
                <a
                  href="https://www.linkedin.com/in/leosaenger/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  linkedin
                </a>
              </span>
            </div>
          </Col>
          <Col md={{ span: 4 }}>
            <h3 style={{ fontSize: 24 }}>Hello! I’m Leo Saenger.</h3>
            <div style={{ borderBottom: "1px solid #000" }}></div>
            <div style={{ marginTop: "1em" }}>
              <p>
                I grew up in Eugene, Oregon where I developed a love for
                distance running and the rain.
              </p>
              <p>
                I’m passionate about cooking, fermentation,
                ceramics, and discovering new restaurants! In a past life, 
                I worked as a graphic designer.
              </p>
              (
              <span
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={props.handleAboutClick}
              >
                Back
              </span>
              )
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
